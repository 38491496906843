import { useState, useEffect } from 'react';

export const getUserDevice = (userAgent: string) => {
  let deviceInfo = { deviceType: 'desktop', isMobile: false, isDesktop: true };

  const regex1 =
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;
  if (regex1.test(userAgent)) {
    deviceInfo = { isMobile: true, isDesktop: false, deviceType: 'mobile' };
  }
  return deviceInfo;
};

function getWindowDimensions() {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  } else {
    return { width: 0, height: 0 };
  }
}

function useDeviceType(paramDeviceType = 'desktop') {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  let deviceResizeTimeout: any;
  const [deviceType, setdeviceType] = useState(
    paramDeviceType === 'agent' ? null : paramDeviceType,
  );

  useEffect(() => {
    function handleResize() {
      clearTimeout(deviceResizeTimeout);
      deviceResizeTimeout = setTimeout(() => {
        setWindowDimensions(getWindowDimensions());
      }, 500);
    }
    try {
      if (typeof window !== 'undefined' && paramDeviceType === 'agent') {
        const device =
          getUserDevice(window?.navigator?.userAgent)?.deviceType || 'desktop';
        setdeviceType(device);
        setWindowDimensions(getWindowDimensions());
      }
    } catch {
      //
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowDimensions.width < 768) {
      setdeviceType('mobile');
    } else if (windowDimensions.width >= 767 && windowDimensions.width < 992) {
      setdeviceType('tablet');
    } else {
      setdeviceType('desktop');
    }
  }, [windowDimensions]);

  return { windowDimensions, deviceType };
}

export { useDeviceType };
