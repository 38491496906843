"use client";
import React from "react";

const CTAButton = ({ title, href }: { title: string; href?: string }) => {
  return (
    <div className="text-center min-w-[stretch] pt-4">
      <a
        href={href}
        className="w-[160px] sm:w-[220px]  bg-[#0cc0df] border-[#0cc0df] text-white transition delay-[0ms] duration-[400ms] ease-[ease] border-solid border sm:border-black sm:hover:border-[#0cc0df] min-w-full p-3 !cursor-pointer sm:hover:bg-[#0cc0df] sm:hover:text-[#fff] md:bg-white md:text-black"
      >
        <span className="m-0 relative whitespace-nowrap">{title}</span>
      </a>
    </div>
  );
};

export default CTAButton;
