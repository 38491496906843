"use client";
import React, { useEffect } from "react";
import { useDeviceType } from "@/src/utils/hooks/screen-type";
import dynamic from "next/dynamic";

const VideoCarouselView = dynamic(
  () => import("./mob-tablet-view/video-carousel-view")
);
const VideoCarouselDesktopView = dynamic(
  () => import("./desktop-view/video-carousel-desktop-view")
);

const VideoCarouselComponent = () => {
  const { deviceType } = useDeviceType("mobile");
  const testimonials = [
    {
      videoId: "tARc_4mROSU?si=7mLNfOj0XDewvPa3",
      title: `Free aab's Innovative Water Bottle Ad Concept Impresses Entrepreneur Naveed`,
    },
    {
      videoId: "VtayUWT4qpQ?si=WMAquewk46HeuW7G",
      title: `Renowned Figure Urges Kashmiris to Support Local Brand from the Region`,
    },
    {
      videoId: "j5Gctrocleo?si=E_WDBZ0hCKvXwdr4",
      title: `Candid Feedback and Insights on a Promising New Startup`,
    },
  ];

  return (
    <>
      {deviceType !== "desktop" && <VideoCarouselView videos={testimonials} />}
      {deviceType === "desktop" && (
        <VideoCarouselDesktopView videos={testimonials} />
      )}
    </>
  );
};

export default VideoCarouselComponent;
